import 'normalize.css';
import './assets/scss/main.scss';
import './../node_modules/swiper/css/swiper.min.css';
import { toggleMenu } from './assets/js/Menu';
import { homeHelpSection } from './assets/js/HomeHelpSection';
import { callbackToggle } from './assets/js/Callback';
import { findVideos } from './assets/js/Video';
import Swiper from 'swiper';
import AOS from 'aos';
import 'aos/dist/aos.css';
import IMask from 'imask';
import 'lazysizes';

const HELP_NODE_ITEMS = document.querySelectorAll('.help__item');

function closeAllHelpImages() {
  [...HELP_NODE_ITEMS].forEach((item) => {
    item.classList.remove('active');
  });
}

const handleChangeImage = (e) => {
  closeAllHelpImages();
  e.path[0].classList.add('active');
};

document.addEventListener('DOMContentLoaded', () => {
  toggleMenu();
  callbackToggle();
  homeHelpSection();
  findVideos();

  console.log(HELP_NODE_ITEMS);

  [...HELP_NODE_ITEMS].forEach((item) => {
    item.addEventListener('mouseenter', handleChangeImage);
  });

  AOS.init();

  const telInputs = document.querySelectorAll('input[type="tel"]');
  const maskOptions = {
    mask: '+{38 (0}00) 000-00-00',
  };

  telInputs.forEach((item) => {
    const mask = IMask(item, maskOptions);
  });

  let swiper = new Swiper('.certificates', {
    loop: false,
    slidesPerView: 2,
    spaceBetween: 30,
    navigation: {
      nextEl: '.certificates .swiper-button-next',
      prevEl: '.certificates .swiper-button-prev',
    },
  });
});
