import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { callbackFormSubmit } from './Forms';

export const closePopup = (form) => {
  const closeBtn = form.querySelector('.close-popup');
  const bg = document.getElementById('background');
  const thanksClose = form.querySelector('.thanks__btn');

  const closeBtnEvent = (e) => {
    e.preventDefault();
    form.classList.remove('open');
    bg.classList.remove('open');
    clearAllBodyScrollLocks();
    document.documentElement.style.overflow = 'auto';
  };

  closeBtn.addEventListener('click', closeBtnEvent);

  const bgEvent = () => {
    form.classList.remove('open');
    bg.classList.remove('open');
    clearAllBodyScrollLocks();
    document.documentElement.style.overflow = 'auto';
  };

  bg.addEventListener('click', bgEvent);
};

const validateName = (nameInput, nameErr) => {
  if (nameInput.value.length == 0) {
    nameErr.classList.remove('hidden');
    nameErr.innerText = 'Поле должно быть заполнено!';
    return false;
  } else {
    nameErr.classList.add('hidden');
  }

  return true;
};

const validateTel = (telInput, telErr) => {
  if (telInput.value.length == 0) {
    telErr.classList.remove('hidden');
    telErr.innerText = 'Поле должно быть заполнено!';
    return false;
  } else if (telInput.value.length < 12) {
    telErr.classList.remove('hidden');
    telErr.innerText = 'Некорректно введен телефон!';
    return false;
  } else {
    telErr.classList.add('hidden');
  }
  return true;
};

const validate = (e) => {
  e.preventDefault();
  const parent = e.target.parentNode;
  const telInput = parent.querySelector('.input__tel');
  const nameInput = parent.querySelector('.input__name');
  const telErr = parent.querySelector('.form-error--tel');
  const nameErr = parent.querySelector('.form-error--name');
  const csrfToken = parent.querySelector('input[name=csrfmiddlewaretoken]');
  if (validateName(nameInput, nameErr) && validateTel(telInput, telErr)) {
    callbackFormSubmit(nameInput.value, telInput.value, csrfToken.value);
  }
};

export const callbackToggle = () => {
  const buttons = document.querySelectorAll('.callback-btn');
  const callBtn = document.getElementById('call-btn');
  const signPopup = document.getElementById('sign');
  const callPopup = document.getElementById('call');
  const bg = document.getElementById('background');

  const sendButton = callPopup.querySelector('.callback-btn--send');
  sendButton.addEventListener('click', validate);
  callPopup.addEventListener('submit', validate);

  [...buttons].forEach((item) => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      callPopup.classList.add('open');
      bg.classList.add('open');
      disableBodyScroll(callPopup);
      document.documentElement.style.overflow = 'hidden';
    });
  });

  const pageCallbackBtn = document.getElementById('page-callback-btn');
  if (pageCallbackBtn) {
    pageCallbackBtn.addEventListener('click', validate);
  }

  callBtn.addEventListener('click', (e) => {
    e.preventDefault();
    const sendButton = signPopup.querySelector('.callback-btn--send');
    sendButton.addEventListener('click', validate);
    signPopup.addEventListener('submit', validate);
    signPopup.classList.add('open');
    bg.classList.add('open');

    disableBodyScroll(signPopup);
    document.documentElement.style.overflow = 'hidden';
  });

  closePopup(signPopup);
  closePopup(callPopup);
};
