import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const toggleMenu = () => {
  const burgerBtn = document.getElementById('burger-btn');
  const navNode = document.getElementById('nav');

  burgerBtn.addEventListener('click', () => {
    let expanded = burgerBtn.getAttribute('aria-expanded');
    if (expanded == 'true') {
      expanded = 'false';
      clearAllBodyScrollLocks();
      document.documentElement.style.overflow = 'auto';
    } else {
      disableBodyScroll(navNode);
      expanded = 'true';
      document.documentElement.style.overflow = 'hidden';
    }
    burgerBtn.setAttribute('aria-expanded', expanded);
    burgerBtn.classList.toggle('open');
    navNode.classList.toggle('open');
  });
};
