const accordionNode = document.getElementById('_accordion');

function clearAll() {
  const items = accordionNode.querySelectorAll('.accordion__item');
  items.forEach((item) => {
    item.classList.remove('active');
  });
}

const handleToggle = ({ target }) => {
  const parent = target.parentNode;
  if (!parent.classList.contains('active')) {
    clearAll();
    parent.classList.add('active');
  } else {
    clearAll();
  }
};

export const homeHelpSection = () => {
  const accordionButtons = accordionNode.querySelectorAll('.accordion__toggle');

  accordionButtons.forEach((btn) => {
    btn.addEventListener('click', handleToggle);
  });
};
