import {clearAllBodyScrollLocks} from "body-scroll-lock";

const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

const getCSRFToken = () => {
    return getCookie('csrftoken');
}

const handleSubmit = () => {
  const openPopups = document.querySelectorAll('.popup.open');
  const thanksPopup = document.getElementById('thanks');
  const thanksCloseBtn = thanksPopup.querySelector('.thanks__btn');
  const bg = document.getElementById('background');

  openPopups.forEach((item) => item.classList.remove('open'));
  thanksPopup.classList.add('open');
  thanksCloseBtn.addEventListener('click', (e) => {
    thanksPopup.classList.remove('open');
    bg.classList.remove('open');
    clearAllBodyScrollLocks();
    document.documentElement.style.overflow = 'auto';
  });
};

export const callbackFormSubmit = (name, tel, csrfToken) => {
    let formData = new FormData();
    formData.append('customer_name', name);
    formData.append('customer_phone', tel);
    formData.append('csrfmiddlewaretoken', csrfToken);

    fetch('/contact_request/', {
        method: 'POST',
        body: formData
    }).then((response) => {
        if (response.status === 201) {
            handleSubmit();
        }
    })
}